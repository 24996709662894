<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <v-card>
      <div>
        <v-container class="custom-container pa-6 mx-0">
          <v-row class="align-center mb-4">
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              cols="12"
            >
              <h2>Rekap Absensi</h2>
              <small
                style="white-space: normal;"
                class="dark--text"
              >Halaman untuk rekap absensi siswa</small>
            </v-col>

            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="6"
              cols="12"
              class="py-3 offset-md-1"
            >
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
                @input="searchHandler($event)"
              ></v-text-field>
            </v-col>

            <v-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
              cols="12"
              class="py-3"
            >
              <v-btn
                color="primary"
                outlined
                dense
                large
                width="100%"
                :disabled="!class_uuid"
                @click="printRecap()"
              >
                Cetak Rekapan
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="my-4">
            <v-col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  label="Pilih Kelas"
                  outlined
                  :items="classes"
                  item-text="name"
                  item-value="uuid"
                  :loading="isLoadingClass"
                  :disabled="isLoadingClass"
                  dense
                  @change="filterByClass($event)"
                ></v-autocomplete>
              </div>
            </v-col>

            <v-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-menu
                  v-model="start_date"
                  :close-on-content-click="true"
                  nudge-right="0"
                  nudge-top="20"
                  max-width="100%"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="recap.start_date"
                      label="Tanggal Mulai"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="autofillStartDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="recap.start_date"
                    :first-day-of-week="0"
                    locale="id-id"
                    color="primary"
                    @input="start_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-menu
                  v-model="end_date"
                  :close-on-content-click="true"
                  nudge-right="0"
                  nudge-top="20"
                  max-width="100%"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="recap.end_date"
                      label="Tanggal Berakhir"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="autofillEndDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="recap.end_date"
                    :first-day-of-week="0"
                    locale="id-id"
                    color="primary"
                    @input="end_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-btn
                  color="primary"
                  dense
                  large
                  width="100%"
                  :disabled="!class_uuid"
                  @click="getAttendances"
                >
                  Tampilkan
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-data-table
        :page="page"
        :headers="headers"
        :items="attendances"
        :loading="isLoadingAttendance"
        :server-items-length="totalItems"
        :total-pages.sync="totalPages"
        :search="search"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:item.button>
          <v-btn
            color="primary"
            dense
          >
            Cetak
          </v-btn>
        </template>
      </v-data-table>

      <div class="d-flex justify-space-between pt-2 pb-2">
        <div class="ml-4 mt-3">
          <h5>Total item: {{ totalItems }}</h5>
        </div>
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify, mdiTownHall } from '@mdi/js'

export default {
  name: 'AttendanceStudent',
  components: {},
  props: {},
  data() {
    return {
      url: '',
      page: 1,
      totalPages: 0,
      totalPageData: 1,
      isLoadingData: false,
      isLoadingAttendance: false,
      isLoadingClass: false,
      totalItems: 0,
      search: '',
      icons: {
        mdiMagnify,
        mdiTownHall,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Kelas', value: 'class' },
        { text: 'Hadir (H)', value: 'attend' },
      ],
      start_date: null,
      end_date: null,
      class_uuid: null,
      recap: {
        class: null,
        start_date: '',
        end_date: '',
      },
      serviceClass: 'classes',
      serviceAttendance: 'attendance',
      attendances: [],
      classes: [],
      instance: {},
      isLoadingInstance: false,
      serviceInstance: 'instance',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      filterQuery: {
        search: '',
      },
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    }
  },
  watch: {
    page: {
      handler() {
        this.getAttendances(this.filterQuery)
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
            this.getAttendances({
              search: this.search,
            })
          }, 500)
        }
      },
    },
  },
  mounted() {
    this.getDetailInstance()
    this.getClasses()
  },
  methods: {
    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
          this.isLoadingInstance = false
        },
      )

      this.isLoadingInstance = false
    },

    async printRecap() {
      await this.$services.RecapServices.recapStudent(this.instanceUuid, {
        per_page: 'all',
        page: this.page,
        class_uuid: this.class_uuid,
        start_date: this.recap.start_date,
        end_date: this.recap.end_date,
      }).then(
        ({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'recap-attendance-student.pdf')
          document.body.appendChild(link)
          link.click()
        },
        err => {
          console.error(err)
        },
      )
    },

    async getClasses(params = {}) {
      this.isLoadingClass = true

      await this.$services.ApiServices.list(this.serviceClass, {
        ...params,
        per_page: 'all',
        page: this.page,
        company_uuid: this.instanceUuid,
      }).then(
        ({ data }) => {
          this.classes = data.data
          this.isLoadingClass = false
        },
        err => {
          console.error(err)
          this.isLoadingClass = false
        },
      )

      this.isLoadingClass = false
    },

    async getAttendances(params = {}) {
      this.isLoadingAttendance = true

      await this.$services.ApiServices.get(this.serviceAttendance, this.instanceUuid, {
        ...params,
        page: this.page,
        class_uuid: this.class_uuid,
        start_date: this.recap.start_date,
        end_date: this.recap.end_date,
      }).then(
        ({ data }) => {
          this.attendances = data.data.map((type, index) => ({
            ...type,
            index: index + 1,
            attend: 'H',
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
          this.isLoadingAttendance = false
        },
        err => {
          console.error(err)
          this.isLoadingAttendance = false
        },
      )

      this.isLoadingAttendance = false
    },

    searchHandler(search) {
      if (this.class_uuid) this.filterQuery.search = search
      this.getAttendances(this.filterQuery)
    },

    filterByClass(uuid) {
      this.class_uuid = uuid
      this.url = `${this.baseUrl}/api/v1/company/${this.instanceUuid}/attendance/recap?class_uuid=${this.class_uuid}`
    },

    autofillStartDate() {
      this.recap.start_date = new Date().toISOString().substr(0, 10)
    },

    autofillEndDate() {
      this.recap.end_date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
    },
  },
}
</script>

<style>
</style>
